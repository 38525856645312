$white: #ffffff;
$primary: #E8C973;
$primarycard: #014e4a;
$black: #121110;
$background: #020202;
$secondary: #d2d0ce;
$danger: #ED0000;
$success: #27B635;
@import 'bootstrap';

body {
  color: $white;
  font-family: 'Kanit', sans-serif;
  background-size: 100%;
  font-size: 14px;
  background: linear-gradient(104deg, #3ea49b, #214a48 43%, #182d2f) fixed;
}
.boxhuay{
  max-width: 180px;
}
.boxhuayx{
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
}
.btn-no{
  
  background: transparent;
  color: #f6ce07;
}
.hkbill{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 1px;
  background-color: #f6ce07;
}

.hkbill2{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 1px;
  background-color: #0d0d0c;
  color: #fff;
}
.hkbill3{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 1px;
  background: transparent;
  color: #fff;
}
.btn1002{
  background: rgb(244, 10, 2);
  background: linear-gradient(344deg, rgb(245, 23, 23) 16%, rgb(247, 13, 29) 85%, rgb(244, 56, 8) 98%);
   border: #f3eeed 1px solid;
   color: #fff;
}
.hk1001{
  width: 100%;
  background-color: #07c07c;
   border: #12e699 1px solid;
  border-radius: 10px;
  padding: 50px;
  text-align: center;
  margin-bottom: 20px;
}
.btn-no:hover{
  background-color: #ebbd04;
}
.mc2001{
  max-width: 40px !important;
}
.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  font-size: 14px;
}

.linefixed {
  position: fixed;
  right: 80px;
  z-index: 100;
  width: 85px;
  height: auto;
  top: 60%;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}
.linefixed img {
  display: block;
  margin: 0 auto;
}

.text-info3{
  color: #fdd826;
}
.dimg{
  text-align: center;
}
.dimg img{
  border-radius: 25px;
  max-width: 50px;
  border: 1px $primary solid;
  text-align: center;
}
.txtm001{
  padding: 10px;
  font-size: 18px;
  color: #f97889;
}
.affpay{
  border: #f42743 1px solid;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.dimg2 img{
  border-radius: 15px;
  max-width: 30px;
  border: 1px $primary solid;
  text-align: center;
}
.ball{
  background-color: #f00a25;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 1px;
  margin-right: 1px;
}
.ball2{
  background-color: #f1a2ab;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 1px;
  margin-right: 1px;
}
.bgwait{
  padding: 20px;
  background-color: #fc980b;
  border-radius: 10px;
  margin-top: 30px;
  font-size: 20px;
}
.bg-top{
  
  background: rgb(255,255,255);
background: linear-gradient(0deg, transparent 58%, rgb(3, 181, 146) 100%);
  color: #fff;
  padding: 10px;
}
.bg-top2{
  
  background: rgb(255,255,255);
background: linear-gradient(0deg, transparent 58%, rgb(3, 181, 146) 100%);
  color: #fff;
  padding: 10px;
}
.ball3{
  background-color: #d8dee3;
  color: #000;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 1px;
  margin-right: 1px;
}
.boderline{
  padding-top: 10px;
  margin-top: 10px;
  border: 1px !important;
  border-top:#727472 1px solid !important;
}
.boxmodalx{
  border-radius: 10px;
  border: #03686acc 1px solid;
  background-color:#026364cc;
  padding-bottom: 20px;
  margin-top: 10px;
}
.copylink1{
  display: block;
  padding: 4px !important;
  background-color: #000 !important;
  color: #ebbd04 !important;
  cursor: pointer !important;
  text-align: center !important;
  color: #fff;
  border-radius: 10px;
  width: 100%;
}

.copylink1ac{
  display: block;
  padding: 4px !important;
  background-color: rgb(240, 181, 3);
  color: #000;
  cursor: pointer !important;
  text-align: center !important;
 
  border-radius: 10px;
  width: 100%;
}


.btnback{

  border: 0px;
 padding-left: 10px;
 padding-right: 10px;
  background-color: rgb(240, 181, 3);
  color: #000;
}
.affcode{
  background-color: #fcc85e !important;
  opacity: 0.8 !important;
  color: #fff !important;
  border: #fff 1px solid !important;
}
.slipBtn{
   padding: 3px;
  border-radius: 25px;
  background-color: #de2103;
  border: #ff4c4c 1px solid;
  min-width: 80px;
  color: #fff;
}
.mxnumber{
  min-width: 60px;
  border-radius: 0px !important;
  color: #ee3507;
  //background-color: #000 !important;
}
.rewardx{
  border-radius: 0px !important;
  color: #06d176;
}
.rewardnox{
  border-radius: 0px !important;
  color: #f30909;
}
.mxnumber2{
  min-width: 110px;
  border-radius: 0px !important;
  background-color: #b99d12 !important;
}
.bg-dangerx{
  background-color: #0ac558 !important;
  min-width: 80px
}
.bg-dangerx2{
  min-width: 80px;
  background-color: #ff4c4c;
}
.bgttoal{
  border-radius: 25px;
  text-align: right;
  padding: 15px;
  background-color: $primarycard;
  border: $primary 2px solid;
  font-size: 18px;
}
.btdis{
  background-color: #fff;
}
.pointer {
  cursor: pointer;
}

input, select {
  box-shadow: none !important;
}

.btn-primary {
  border: none;
  background: linear-gradient(180deg, #F2D78A 0%, #8A7129 100%) !important;
}

.btn-primary-no {
  border: none;
  background: linear-gradient(180deg, #878684 0%, #7f7e7d 100%) !important;
}

.overbill{
  background-color: rgb(6, 183, 3);
  border: #F30000 1px solid;
}
.popup-modal {
  .modal-content {
    background: transparent;
  }

  .contentmodal {
    width: 100%;
    max-width: 420px;
    height: auto;
    z-index: 2;
    position: relative;
    margin: 0 auto;
   
    border-radius: 20px;
    padding: 10px;
  }


  input {
    background: #00000085;
    width: 100%;
    border-radius: 9px;
    border: none;
    padding: 10px;
    outline: none;
    color: white;
    box-shadow: 0 0 2px white, 0 0 15px #0000009e;
  }

  .btn-login-modal {
    background: linear-gradient(90deg, #fdd826 0%, #fdb311 100%);
    border: 1px solid #fdd522;
    color: white;
  }

  .closeloginbtn {
    position: absolute;
    right: 2px;
    top: -20px;
    border-radius: 50px;
    border:none;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    background: #f64747;
    padding: 0;
    z-index: 3;
    cursor: pointer;
  }
}
.login-modal {
  .modal-content {
    background: transparent;
  }

  .contentmodal {
    width: 100%;
    max-width: 350px;
    height: auto;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    background: linear-gradient(117deg, #09998d, #027173cc);
    border-radius: 20px;
    padding: 10px;
  }


  input {
    background: #00000085;
    width: 100%;
    border-radius: 9px;
    border: none;
    padding: 10px;
    outline: none;
    color: white;
    box-shadow: 0 0 2px white, 0 0 15px #0000009e;
  }

  .btn-login-modal {
    background: linear-gradient(90deg, #fdd826 0%, #fdb311 100%);
    border: 1px solid #fdd522;
    color: white;
  }

  .closeloginbtn {
    position: absolute;
    right: 2px;
    top: -10px;
    border-radius: 50px;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    background: #ff4c4c;
    padding: 0;
    z-index: 3;
    cursor: pointer;
  }
}


.btn-danger {
  border: none;
  background: linear-gradient(180deg, #F30000 0%, #8A0000 100%);
}

.one-logo {
  border: 1px solid white;
}
.btnselec_normal{
  border: 0px;
  border-radius: 5px;
  background-color: #c0bcbc;
  color: #8a8686;
}
.btnselec{
  border: 0px;
  border-radius: 5px;
  background-color: #eecb07;
  color: #020202;
}
.custom__input {
  color: inherit !important;
  background-color: transparent !important;
  box-shadow: none;
  outline: none;
  border-color: #89857C !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #757068;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #757068;
  opacity: 0.2;
}

.separator:not(:empty)::before {
  margin-right: 25px;
}

.separator:not(:empty)::after {
  margin-left: 25px;
}

.swiper-image {
  border-radius: 18px;
}


.card-green {
  border-radius: 16px;
  /*background-color: #142c30;*/
  background-color: $primarycard;
  color: $white;

  input::placeholder {
    color: $secondary;
  }
}

.card-dark {
  border-radius: 16px;
  border: 1px solid rgba(117, 112, 104, 0.20);
  background: #121110;
  color: $secondary;

  .menu-card {
    width: 54px;
    border-radius: 12px;
    padding: 8px;
    background: rgba(117, 112, 104, 0.2);
    margin-bottom: 8px;
  }

  .menu-name {
    font-size: 90%;
  }

  .img-affiliate {
    margin-top: -25px;
  }
}


.card-pink {
  border-radius: 16px;
  border: 1px solid rgba(210, 8, 183, 0.2);
  background: #c48206;
  color:#dad5d5;

  .menu-card {
    width: 54px;
    border-radius: 12px;
    padding: 8px;
    background: rgba(117, 112, 104, 0.2);
    margin-bottom: 8px;
  }

  .menu-name {
    font-size: 90%;
  }

  .img-affiliate {
    margin-top: -25px;
  }
}


.movie {
  .name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap;
  }
}

/* main menu */
.main-menu {
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(180deg, #142c30 0%, #181714 100%);
  box-shadow: 0px -10px 12px 0px rgba(0, 0, 0, 0.38);
  font-size: 90%;

  .menu-icon {
    height: 25px;
    margin-bottom: 8px;
  }

  a {
    text-decoration: none;
    color: $secondary;

    &.active {
      color: $primary;
    }
  }
}

/* main menu */


/* otp */
.otp-space {
  width: 15px;
}

.otp-input {
  background-color: transparent;
  color: $white;
  padding: 0;
  height: 50px !important;
  width: 50px !important;
  font-size: 22px;
  box-shadow: none;
  outline: none;
  border: 1px solid $secondary;
  border-radius: 8px;
}

/* end otp */

.affiliate, .history {
  .list {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(117, 112, 104, 0.20);
  }

  .table > :not(caption) > * > * {
    color: inherit;
    background-color: transparent;
  }

  th, td {
    border-bottom: 1px solid rgba(117, 112, 104, 0.20);
  }

  .one-logo {
    max-width: 15px;
  }


  ul {
    list-style: none
  }

  ul li::before {
    content: "\2022";
    color: $primary;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

}


.affiliate-menu {
  text-align: center;
  color: $secondary;

  .menu-item {
    padding: 8px 15px;
    cursor: pointer;
  }

  .active {
    color: $black;
    background-color: $primary;
    border-radius: 30px;
  }
}

.bill {
  .number {
    border: 1px solid $secondary;
    color: $white;
    padding: 4px 8px;
    border-radius: 8px;
  }

  .result {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 35px;
    border: 2px solid $primary;
    color: $white;
    border-radius: 8px;
  }

  .card-dark {
    border-radius: 8px;
    background-color: rgba(18, 17, 16, 0.5);
    border: 1px solid transparent;
  }

  .card-pink {
    border-radius: 8px;
    background-color: rgba(246, 14, 99, 0.5);
    border: 1px solid transparent;
  }

  .card-transparent {
    font-size: 90%;
    background-color: transparent;
    color: $secondary;
    border: 1px solid rgba(117, 112, 104, 0.50);
  }

  .card-green {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: none;
  }
}

.cart {
  .list {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(117, 112, 104, 0.20);
  }

  .icon-animal {
    width: 50px;
  }

  .number {
    font-size: 18px;
  }

  .icon-cancel {
    cursor: pointer;
    width: 23px;
  }

  .amount {
    color: $primary;
    font-size: 18px;
  }
}

.tumra-container {
  margin-bottom: 280px;

  .list {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(117, 112, 104, 0.20);
  }

  .icon-animal {
    width: 60px;
  }

  .number {
    cursor: pointer;
    text-align: center;
    min-width: 40px;
    border-radius: 8px;
    border: 1px solid $secondary;
    padding: 2px;
    font-size: 18px;

    &.active {
      background-color: $primary;
      color: $black;
    }
  }

  .all-number {
    cursor: pointer;
    color: $primary;
    border: 1px solid $primary;
    text-align: center;
    border-radius: 8px;
    padding: 4px;
  }
}

.btn-logout {
  background-color:#ef4136;
  padding: 4px;
  border-radius: 8px;

  .icon-back {
    height: 25px;
  }
}
.btn-back {
  background-color: $primary;
  padding: 4px;
  border-radius: 8px;
  color: #000;
  .icon-back {
    height: 25px;
  }
}

.lotto-menu {
  ul {
    list-style: none
  }
}

.selected-result {
  background-color: #282420;
  height: 58px;
  padding: 4px 8px;
  border-radius: 8px;

  .numbers {
    font-size: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap;
  }
}

.lotto-container {
  margin-bottom: 280px;

  .card-green {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: none;
  }

  .countdown {
    .box {
      border-radius: 8px;
      padding: 10px;
      background-color: $danger;
    }

    .time {
      width: 45px;
      font-size: 34px;
      line-height: 1.2;
    }

    .colon {
      font-size: 34px;
    }
  }

  .result {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    height: 40px;
    width: 35px;
    border: 2px solid $primary;
    color: $white;
    border-radius: 8px;
  }

  .bg-changkum {
    background-size: 100%;
    background-image: url('./assets/images/bg-changkum.png');
    background-position: top right;
    background-repeat: no-repeat;
    font-size: 14px;
  }


}

.lotto-tab, .bill-tab, .hoon-tab {
  .nav-tabs {
    border: none;
    margin-bottom: -2px;
    font-size: 18px;
  }

  .nav-tabs .nav-link.active {
    border-color: #142c30;
    color: $primary;
    background-color: #142c30;
  }

  .nav-tabs .nav-link {
    border-color: #142c30;
    color: $secondary;
    background: rgba(20, 44, 48, 0.5);
    padding: 15px 8px;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .icon {
    height: 25px;
    margin-right: 8px;
  }
}

.hoon {
  .board {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: none;
  }
}


.hr-secondary {
  border-top: 1px solid rgba(117, 112, 104, 0.2);
}

.hr-primary {
  border-top: 1px solid rgba(232, 201, 115, 0.2)
}

.tansamai-menu-bottom {
  background: #142c30;
  box-shadow: 0px -10px 12px 0px rgba(0, 0, 0, 0.38);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  textarea {
    resize: none;
    overflow: auto;
    height: 57px;
    cursor: pointer;
    color: $white;
    border-radius: 12px;
    border: 1px solid #757068;
    background: #214a48;
    box-shadow: none !important;
    outline: none !important;
  }

  textarea::placeholder {
    color: $secondary;
  }

  .menu-icon {
    width: 25px;
  }

  .btn__custom {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    flex-direction: column;
    border-radius: 8px;
  }

  .btn-tumra, .btn-random-number {
    color: $white;
    text-decoration: none;
    background-color: $secondary;
  }

  .btn__primary {
    background-color: $primary;
    cursor: pointer;
  }

  .btn-add {
    color: $black;
    background-color: $primary;
  }

  .custom__textarea {
    // background-color: #353028;
    padding: 8px 16px;
    border-radius: 8px;

    input {
      background-color: transparent;
      border: none;
      color: $white;
      padding: 0;
      font-size: 18px;
    }
  }

  .input-search {
    border: 1px solid $primary;
  }

  .input-money {
    border: 1px solid $secondary;
  }

  .btn-money {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    cursor: pointer;
    color: $secondary;
    background-color: rgba(18, 17, 16, 0.5);
    border-radius: 4px;
    text-align: center;

    &:hover {
      background-color: $primary;
    }
  }

  .total-box {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: linear-gradient(180deg, #524F4D 0%, #201D1B 100%);
    height: 60px;
  }

  .total {
    font-size: 28px;
  }

  .btn__payment {
    border-radius: 0 8px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary;
    padding: 0.4rem 0.75rem;
    color: $black;
    line-height: 1;
  }
}

.namsud {
  .icon-animal {
    height: 40px;
    margin-bottom: 4px;
  }

  .number {
    color: $primary;
    font-size: 22px;
  }

  .name {
    font-size: 80%;
  }

  .number-box {
    padding: 8px;
    border-radius: 8px;

    &.primary {
      border: 1px solid $primary;
      background: rgba(53, 48, 40, 1)
    }

    &.success {
      border: 1px solid $success;
      background: rgba(42, 78, 14, 0.27);
    }

    &.danger {
      border: 1px solid $danger;
      background: rgba(237, 0, 0, 0.1);
    }

    .icon-top-right {
      position: absolute;
      right: -10px;
      top: -10px;
    }

    .icon-close {
      width: 25px;
    }
  }

  .selected-box {
    border: 1px solid $primary;
    border-radius: 4px;
    padding: 2px 4px;

    .icon {
      height: 25px;
      width: 25px;
    }
  }
}

.namsud-menu-bottom {
  background: linear-gradient(180deg, #373633 0%, #181714 100%);
  box-shadow: 0px -10px 12px 0px rgba(0, 0, 0, 0.38);
  border-top-right-radius: 8px;

  .btn-checkout {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    background-color: $primary;
    color: $black;
    height: 40px;
    font-size: 18px;

    &:hover {
      background-color: $primary;
    }

    .icon-next {
      position: absolute;
      right: 15px;
      width: 25px;
    }
  }

  .btn__custom {
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    padding: 1rem;
  }

  .btn-select-number {
    height: 22px;
    background-color: rgba(60, 59, 56, 1);
  }

  .btn-random-number {
    height: 22px;
    color: $black;
    background-color: $primary;
  }

  .total {
    color: $primary;
    font-size: 22px;
    line-height: 1;
  }

  .btn-add {
    padding: 0.3rem 0.8rem;
    background-color: $success;
  }

  .btn-remove {
    padding: 0.3rem 0.8rem;
    background-color: $danger;
  }

  .icon-add, .icon-remove {
    width: 25px;
  }


  .selected-box {
    overflow: hidden;
    z-index: 1;
    border-radius: 8px;
    border: 1px solid $white;

    .header {
      z-index: 0;
      text-align: center;
      color: $black;
      background-color: $primary;
    }

    .body {
      padding: 4px;
    }

    .icon {
      width: 25px;
    }

    .number {
      font-size: 16px;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 3px;
    grid-template-areas: ". . . . . . . .";

    .number-box {
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid $white;
      display: flex;
      text-align: center;
      overflow: hidden;

      &.active {
        opacity: 0.2;
      }
    }

    .icon {
      width: 25px;
    }

    .header {
      background-color: $primary;
      font-size: 8px;
      border-radius: 7px 0 0 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      color: $black;

    }

    .name {
      white-space: nowrap;
      transform: rotate(270deg);
    }
  }

  .open-menu {
    cursor: pointer;
    padding: 8px 15px 0 15px;
    position: absolute;
    top: -30px;
    background: #373633;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}


.suggestion-result {
  .suggestion-icon {
    height: 40px;
    padding: 5px;
    background-color: #3c3b38;
    border-radius: 8px;
  }

  .suggestion-number {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: normal;
    min-width: 40px;
    border-radius: 8px;
    border: 1px solid #757068;
    padding: 4px;
  }
}

.hoon {
  .grid-container {
    display: grid;
    gap: 5px;

    &.hundred {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    &.ten {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .number-box {
      cursor: pointer;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      border-radius: 4px;
      border: 1px solid rgba(117, 112, 104, 0.20);

      &.type_3.active {
        background-color: $danger;
      }

      &.type_2.active {
        background-color: $info;
      }

      &.type_0.active {
        background-color: $success;
      }
    }
  }

  .select-range {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(117, 112, 104, 0.5);

    &.type_3.active {
      background-color: $danger;
    }

    &.type_2.active {
      background-color: $info;
    }

    &.type_0.active {
      background-color: $success;
    }
  }

  .select-mode {
    cursor: pointer;
    text-align: center;
    border: 1px solid rgba(117, 112, 104, 0.5);
    border-radius: 4px;


    &.type_3.active {
      background-color: $danger;
    }

    &.type_2.active {
      background-color: $info;
    }

    &.type_0.active {
      background-color: $success;
    }


  }


}


.home {
  .profile {
    .one-logo {
      max-height: 20px;
    }

    .icon {
      max-height: 20px;
    }
  }
}

.withdraw {
  .table > :not(caption) > * > * {
    background-color: transparent;
    color: inherit;
  }
}

.deposit, .hoon {
  .btn-money {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 40px;
    cursor: pointer;
    color: $secondary;
    background-color: #d84106;
    /*background-color: rgba(18, 17, 16, 0.5);*/
    border-radius: 4px;
    text-align: center;

    &:hover {
      background-color:#ed5a1f;
    }
  }
  .btn-money2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 40px;
    cursor: pointer;
    color: $secondary;
    background-color: #0c4acf;
    /*background-color: rgba(18, 17, 16, 0.5);*/
    border-radius: 4px;
    text-align: center;

    &:hover {
      background-color: #2263f0;
    }
  }
  .btn-money3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 40px;
    cursor: pointer;
    color: $secondary;
    background-color: #0d956d;
    /*background-color: rgba(18, 17, 16, 0.5);*/
    border-radius: 4px;
    text-align: center;

    &:hover {
      background-color: #0eb683;
    }
  }
  .btn-money4 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 40px;
    cursor: pointer;
    color: #000;
    background-color: #f1c448;
    /*background: linear-gradient(180deg, #f1c448 0%, #8A7129 100%) !important;*/
    /*background-color: rgba(18, 17, 16, 0.5);*/
    border-radius: 4px;
    text-align: center;

    &:hover {
      background-color: $primary;
    }
  }
}

.hoon {
  .cart-list {
    .input-group-text {
      border-radius: 0;
      width: 55px;
     background-color: #d3d5d4;
     // color: #fff;
    }

    .reward {
      font-size: 80%;
      min-width: 150px;
    }

    .btn-delete {
      border-radius: 0;
    }

    .bet.bg-danger {
      color: $white
    }
  }

  .numpad {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0;
    height: 50px;
    background-color: rgba(18, 17, 16, 0.5);
    border-radius: 4px;
    margin-bottom: 8px;
    cursor: pointer;

    &.btn-numpad {
      font-size: 14px !important;
    }
  }

}

.hoon-list, .hoon {
  .card {
    border: none;
    border-radius: 8px;
  }

  .xribbonlotto {
    padding: 4px 8px;
    display: inline-block;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #d82c1f;
    color: #fff;
    font-size: 14px;
  }

  .header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 8px;
  }

  .flags {
    position: absolute;
    top: 0;
    right: 0;
    height: 37px;
    width: 37px;
    background-repeat: no-repeat;
    background-size: contain;
    border-top-right-radius: 8px;
  }

  .bgthai1 {
    background: linear-gradient(90deg, #295cd5 24%, #134ab8 99%);
  }

  .bgthai2 {
    background: linear-gradient(90deg, #ed1b95 24%, #e80f8d 99%);
  }

  .bglao {
    background: linear-gradient(90deg, #002768 24%, #002768 99%);
  }

  .bginter {
    background-color: #0848d4;
  }

  .boxth {
    background-image: url(https://skylot44.com/images/th.png);
  }

  .boxaom {
    background-image: url(https://skylot44.com/images/aom.png);
  }

  .boxlaolottery {
    background-image: url(https://skylot44.com/images/laolottery.png);
  }

  .boxlao {
    background-image: url(https://skylot44.com/images/lao.png);
  }

  .boxlaomidnight {
    background-image: url(https://skylot44.com/images/laomidnight.png);
  }

  .boxhanoyplus {
    background-image: url(https://skylot44.com/images/hanoyplus.png);
  }

  .boxvn {
    background-image: url(https://skylot44.com/images/vn.png);
  }

  .boxmalay {
    background-image: url(https://skylot44.com/images/malay.png);
  }

  .boxnikai {
    background-image: url(https://skylot44.com/images/nikai.png);
  }

  .boxchaina {
    background-image: url(https://skylot44.com/images/chaina.png);
  }

  .boxchai2 {
    background-image: url(https://skylot44.com/images/chai21.png);
  }

  .boxtaiwan {
    background-image: url(https://skylot44.com/images/taiwan.png);
  }

  .boxkorea {
    background-image: url(https://skylot44.com/images/korea.png);
  }

  .boxsingpro {
    background-image: url(https://skylot44.com/images/singpro.png);
  }

  .box3d {
    background-image: url(https://imgcloudgame.com/v1/huay/h3d.jpg);
  }
  .boxindain {
    background-image: url(https://skylot44.com/images/indain.png);
  }

  .boxeyip {
    background-image: url(https://skylot44.com/images/eyip.png);
  }

  .boxrussai {
    background-image: url(https://skylot44.com/images/russai.png);
  }

  .boxengland {
    background-image: url(https://skylot44.com/images/england1.png);
  }

  .boxyeraman {
    background-image: url(https://skylot44.com/images/yeraman.png);
  }

  .boxdownjone {
    background-image: url(https://skylot44.com/images/downjone.png);
  }


  .lottoclose {
    filter: opacity(70%) grayscale(100%);
  }


}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.img-payment {
  width: 38px;
}

.huay-type {
  .card-green {
    border-radius: 4px;
  }

  .table > :not(caption) > * > * {
    color: inherit;
    background-color: transparent;
  }

}

.my-bill {
  .number {
    border: 1px solid $secondary;
    color: $white;
    padding: 4px 8px;
    border-radius: 8px;
  }

  .result {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 35px;
    border: 2px solid $primary;
    color: $white;
    border-radius: 8px;
  }

  .card-dark {
    border-radius: 8px;
    background-color: rgba(18, 17, 16, 0.5);
    border: 1px solid transparent;
  }

  .card-pink {
    border-radius: 8px;
    background-color: rgba(58, 66, 79, 0.5);
    border: 1px solid transparent;
  }

  .card-transparent {
    font-size: 90%;
    background-color: transparent;
    color: $secondary;
    border: 1px solid rgba(117, 112, 104, 0.50);
  }
}

.bill-menu {
  text-align: center;
  color: $secondary;

  a {
    padding: 8px 15px;
    cursor: pointer;
    text-decoration: none;
  }

  .active {
    color: $black;
    background-color: $primary;
    border-radius: 30px;
  }
}


@media (max-width: 550px) {
  .gridgame.alone {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .linefixed {
    position: fixed;
    right: 10px;
    z-index: 100;
    width: 85px;
    height: auto;
    top: 75%;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
  }
  .linefixed img {
    display: block;
    margin: 0 auto;
    height:auto;
    width: 70px;
  }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.bankcontainer img {
  width: 25px;
  margin: 1px;
  height: auto;
}

.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #f7f097;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#fff000;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #fff000;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #fff000;
  border-right:3px solid #fff000;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.span1
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.span1:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#fff000;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #fff000;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}